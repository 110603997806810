let shadowRoot;

window.addEventListener('heyflow-init', (event) => {
    shadowRoot = 
        (document.querySelector('heyflow-wrapper') &&
            document.querySelector('heyflow-wrapper').shadowRoot) ||
        document;
        
        console.log(shadowRoot);
});


window.addEventListener('heyflow-change', (event) => {
    console.log(event.detail.screenName);

    // if (event.detail.screenName === "contact-info-solar-pv") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-f65754f0");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-199e87e4");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-339a5351");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-solar-thermal") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-fff9cd12");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-1e303fbd");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-895a2b38");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-boiler") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-da01da71");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-999c1775");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-79fee3a2");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-smart-controls") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-d64decf6");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-60800c01");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-ca2f1a4a");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-hydrogen") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-480fd914");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-2f697bf3");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-5b8ba8d9");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-hybrid-systems") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-9f944755");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-2a71fed6");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-683bda1b");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-gshp") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-aef175b3");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-3123ae9f");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-9e10fb43");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-ashp") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-42b041a5");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-96a2a09b");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-1d7b196b");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-ev-charging") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-e12e31ba");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-bb6a0267");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-4e083a2f");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-other") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-19ec4aa0");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-4a541148");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-1b54f9f0");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-heatforce-care") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-41b83680");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-6677e5b9");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-93eac32b");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }
    
    // if (event.detail.screenName === "contact-info-general") {
        
    //     // Get the container holding the radio buttons
    //     const radioContainer = shadowRoot.querySelector("#multiple-choice-block-id-788deacc");

    //     // Get the selected radio button element
    //     const selectedRadio = radioContainer.querySelector('input[type="radio"]:checked');

    //     var selectedValue;

    //     // If a radio button is selected, get its value
    //     if (selectedRadio) {
    //     selectedValue = selectedRadio.dataset.answer;
    //     console.log("Selected value:", selectedValue);
    //     // You can use the selectedValue variable for further processing
    //     } else {
    //     console.log("No option selected");
    //     }


    //     var datePickerContainer = shadowRoot.querySelector("#date-picker-id-34abb5a6");
    //     var timePickerContainer = shadowRoot.querySelector("#select-block-id-29d355f1");


    //     console.log(datePickerContainer.style.display);
    //     console.log(timePickerContainer.style.display);

    //     if(selectedValue == "Scheduled Call"){

    //         console.log("Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "block";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "block";
    //         }
    //     }
    //     else{

    //         console.log("Do Not Schedule a Call");

    //         if (datePickerContainer) {
    //             datePickerContainer.style.display = "none";
    //         }
            
    //         if (timePickerContainer) {
    //             timePickerContainer.style.display = "none";
    //         }
    //     }
            
    // }

});
